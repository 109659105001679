import { FieldTypes, FormConfig } from 'src/component/CRUDTemplate/types';

export const addForm: FormConfig = {
  fields: [
    {
      name: 'abstractProductSlug',
      label: 'Abstract product slug',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'suggestedProductUid',
      label: 'Suggested Product UID',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'generationType',
      label: 'Generation type',
      type: FieldTypes.InputTextField,
      required: true,
    },
  ],
};

export const editForm: FormConfig = {
  fields: [
    {
      name: 'abstractProductSlug',
      label: 'Abstract product slug',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'suggestedProductUid',
      label: 'Suggested Product UID',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'generationType',
      label: 'Generation type',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'theme',
      label: 'Theme',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'enabled',
      label: 'Enabled',
      type: FieldTypes.InputCheckboxField,
      required: true,
    },
  ],
};
