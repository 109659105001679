import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputHidden from 'src/component/Input/InputHidden';
import InputNumber from 'src/component/Input/InputNumber';
import InputSelect, { SelectOption } from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import requiredValidator from 'src/validator/requiredValidator';

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const AttributeEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyItem: AdminBundle.Entity.API.AttributeControllerV1.IAttributeAddV1Request = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    attributeTypeId: undefined,
    hexColor: undefined,
    iconUrl: undefined,
    sortOrder: 0,
    value: '',
  };

  const [
    editedItem,
    setEditedItem,
  ] = useState<AdminBundle.Entity.API.AttributeControllerV1.IAttributeAddV1Request
    | AdminBundle.Entity.API.AttributeControllerV1.IAttributeEditV1Request>(emptyItem);
  const [title, setTitle] = useState('Add new attribute');
  const [subTitle, setSubTitle] = useState('');
  const [attributeTypeSelectItems, setAttributeTypeSelectItems] = useState<SelectOption[]>([]);
  const [isGapiAttribute, setIsGapiAttribute] = useState(false);
  const params = useParams<{ attributeId: string | undefined }>();

  const { attributeId } = params;
  const fetchData = async (id?: string) => {
    setIsLoading(true);

    const attributeTypesList = await apiRequest(
      ApiEndpoint.attributeTypeV1List,
      {
        filter: 1,
      },
      'data.list',
    ) as AdminBundle.Entity.API.AttributeTypeControllerV1.AttributeTypeListV1Response.AttributeType[];

    const attrTypeSelectItems: SelectOption[] = [{
      value: undefined,
      label: '--- NONE ---',
    }, ...attributeTypesList.map((type) => (
      {
        value: `${type.attributeTypeId}`,
        label: type.gApiName ? `${type.name} (${(type.gApiName)})` : type.name,
      }
    ))];

    setAttributeTypeSelectItems(attrTypeSelectItems);

    if (id) {
      setTitle('Edit attribute');
      setSubTitle(id);
      const response = await apiRequest(
        ApiEndpoint.attributeV1Get,
        {
          attributeId: id,
        },
        'data',
      ) as AdminBundle.Entity.API.AttributeControllerV1.AttributeGetV1Response;

      const attributeType = attributeTypesList.find((t) => t.attributeTypeId === response.attributeTypeId);
      if (attributeType && attributeType.gApiName) {
        setIsGapiAttribute(true);
      } else {
        setIsGapiAttribute(false);
      }

      setSubTitle(`${attributeType?.name}: ${response.value}`);

      setEditedItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(attributeId);
  }, [attributeId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.attributeList));
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={editedItem}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              attributeId ? ApiEndpoint.attributeV1Edit : ApiEndpoint.attributeV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setEditedItem(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
            <Card className="mb-3">
              {attributeId ? (
                <InputHidden name="attributeId" />
              ) : null}
              {!isGapiAttribute ? (
                <InputSelect
                  label="Attribute type"
                  validate={requiredValidator}
                  name="attributeTypeId"
                  options={attributeTypeSelectItems}
                />
              ) : <InputHidden name="attributeTypeId" />}
              {!isGapiAttribute ? (
                <InputText
                  name="value"
                  label="Value"
                  validate={requiredValidator}
                />
              ) : <InputHidden name="value" />}
              <InputNumber
                name="sortOrder"
                label="Sort order"
              />
              <InputText
                name="hexColor"
                label="Hex color"
              />
              <InputText
                name="hexColor2"
                label="Hex color 2"
              />
              <InputText
                name="iconUrl"
                label="Icon url"
              />
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default AttributeEdit;
