import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import CRUDTemplateForm from 'src/component/CRUDTemplate/CRUDTemplateForm';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import {
  addProductDesignGenerationCandidate,
  fetchProductDesignGenerationCandidateById,
  updateProductDesignGenerationCandidate,
} from 'src/service/AbstractProductDesignGenerationCandidate';
import { addForm, editForm } from './config';

const ProductDesignGenerationCandidatesEdit = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('Add new abstract product design generation candidate');
  const emptyItem: AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateAddV1Request = {
    abstractProductSlug: '',
    generationType: '',
    suggestedProductUid: '',
  };

  const [
    editedItem,
    setEditedItem,
  ] = useState<
    AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateAddV1Request
    | AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateEditV1Request>(emptyItem);

  const params = useParams<{ id: string | undefined }>();
  const { id: abstractProductGenerationCandidateId } = params;

  const fetchData = async (id?: string) => {
    setIsLoading(true);
    if (id) {
      setTitle('Edit new abstract product design generation candidate');
      const response = await fetchProductDesignGenerationCandidateById(
        +id,
      ) as AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateGetV1Response;
      setEditedItem(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(abstractProductGenerationCandidateId);
  }, [abstractProductGenerationCandidateId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.abstractProductDesignGenerationCandidate));
  };

  return (
    <PageContent title={title} isLoading={isLoading}>
      <CRUDTemplateForm
        editedItem={editedItem}
        formConfig={abstractProductGenerationCandidateId ? editForm : addForm}
        onSubmit={async (values) => {
          setIsLoading(true);
          try {
            if (abstractProductGenerationCandidateId) {
              await updateProductDesignGenerationCandidate(values);
            } else {
              await addProductDesignGenerationCandidate(values);
            }
            onBackClick();
          } catch (e) {
            setEditedItem(values);
            setIsLoading(false);
          }
        }}
        onBackClick={onBackClick}
      />
    </PageContent>
  );
};

export default ProductDesignGenerationCandidatesEdit;
