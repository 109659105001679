import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { HttpMethods } from 'src/api/types';

export const listContentfulSync = () => apiRequest(
  ApiEndpoint.contentfulSyncV1List,
  {},
  'data',
);

export const getContentfulSync = (id: string) => apiRequest(
  ApiEndpoint.contentfulSyncV1Get,
  {
    id,
  },
  'data',
);

export const editContentfulSync = (
  values: AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSync,
) => apiRequest(
  ApiEndpoint.contentfulSyncV1Edit,
  { entity: values },
  'data',
  HttpMethods.POST,
);

export const deleteContentfulSync = (id: number) => apiRequest(
  ApiEndpoint.contentfulSyncV1Delete,
  { id },
  'data',
  HttpMethods.POST,
);

export const addContentfulSync = (
  values: AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncAddV1Request,
) => apiRequest(
  ApiEndpoint.contentfulSyncV1Add,
  values,
  'data',
  HttpMethods.POST,
);

export const bulkEdit = (
  request: AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncBulkEditV1Request,
) => apiRequest(
  ApiEndpoint.contentfulSyncV1BulkEdit,
  request,
  'data',
  HttpMethods.POST,
);
