import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import { SelectOption } from 'src/component/Input/InputSelect';
import InputSelectControlled from 'src/component/Input/InputSelectControlled';
import InputTextControlled from 'src/component/Input/InputTextControlled';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import { cloneDeep } from 'lodash';
import productTypeGroupSelectOptions from 'src/util/selectOptions/productTypeGroupSelectOptions';

type Mapping = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleListV1Response.Group.BundleItem.Mapping;
type IMapping = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleListV1Response.Group.BundleItem.IMapping;
type OpProduct = AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductGetV1Response.OpProduct;
type BundleItem = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleSaveV1Request.Group.BundleItem;
type Group = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleSaveV1Request.Group;
type IBundleItem = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleSaveV1Request.Group.IBundleItem;
type AbstractProductGetV1Response = AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductGetV1Response;
type AbstractProductListV1Response = AdminBundle.Entity.API.AbstractProductControllerV1.AbstractProductListV1Response;
type AbstractProductBundleListV1Response = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleListV1Response;
type AbstractProductBundleSaveV1Request = AdminBundle.Entity.API.AbstractProductBundleControllerV1.AbstractProductBundleSaveV1Request;

export interface OwnProps {
}

export interface Props extends OwnProps {

}

interface UidMappingStorage {
  [abstractProductId: number]: OpProduct[];
}

interface OpenedBundledItemSelector {
  groupIndex: number;
  itemIndex: number;
  parentProductId: number;
  bundledAbstractProductId: number;
}

const AbstractProductBundles = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const params = useParams<{ abstractProductId: string }>();
  const currentAbstractProductId = Number(params.abstractProductId);
  const [bundles, setBundles] = useState<AbstractProductBundleListV1Response | AbstractProductBundleSaveV1Request>({} as AbstractProductBundleListV1Response);
  const [mainAbstractProduct, setMainAbstractProduct] = useState<AbstractProductGetV1Response>({} as AbstractProductGetV1Response);
  const [abstractProductSelectOptions, setAbstractProductSelectOptions] = useState<SelectOption[]>([]);
  const [selectedAbstractProductsForGroups, setSelectedAbstractProductsForGroups] = useState<SelectOption[]>([]);
  const [selectorOptionsForChildProducts, setSelectorOptionsForChildProducts] = useState<SelectOption[]>([]);
  const [selectedBundledProductOption, setSelectedBundledProductOption] = useState<SelectOption | null | undefined>(null);
  const [uidMapping, setUidMapping] = useState<UidMappingStorage>({});
  const [openedBundledItemSelector, setOpenedBundledItemSelector] = useState<OpenedBundledItemSelector | null | undefined>(null);

  const fetchBundles = async (abstractProductId: number) => {
    const bundlesListResponse = await apiRequest(
      ApiEndpoint.abstractProductBundlesV1List,
      { abstractProductId },
      'data',
      'GET',
    ) as AbstractProductBundleListV1Response;
    setBundles(bundlesListResponse);
    const abstractProductIds: number[] = [];
    bundlesListResponse.groups.forEach((g) => {
      g.bundleItems?.forEach((i) => {
        abstractProductIds.push(i.abstractProductId);
      });
    });
    const uniqueAbstractProductIds = [...new Set(abstractProductIds)];
    await fetchAbstractProductUidMappings(uniqueAbstractProductIds);
  };

  const fetchAbstractProductUidMappings = async (abstractProductIds: number[]) => {
    const promises = abstractProductIds.map((abstractProductId) => {
      if (uidMapping[abstractProductId]) {
        return undefined;
      }

      return apiRequest(
        ApiEndpoint.abstractProductV1Get,
        { abstractProductId },
        'data',
        'GET',
      );
    });

    const responses = await Promise.all(promises);
    const uidMappingClone = cloneDeep(uidMapping);
    (responses as AbstractProductGetV1Response[]).forEach((response) => {
      uidMappingClone[response.abstractProductId] = [];
      response.opProducts.forEach((p) => {
        uidMappingClone[response.abstractProductId].push(p);
      });
    });
    setUidMapping(uidMappingClone);
  };

  const fetchMainAbstractProduct = async (abstractProductId: number) => {
    const response = await apiRequest(
      ApiEndpoint.abstractProductV1Get,
      { abstractProductId },
      'data',
      'GET',
    ) as AbstractProductGetV1Response;
    setMainAbstractProduct(response);

    const uidMappingClone = cloneDeep(uidMapping);
    uidMapping[response.abstractProductId] = [];
    response.opProducts.forEach((p) => {
      uidMapping[response.abstractProductId].push(p);
    });
    setUidMapping(uidMappingClone);
  };

  const getAbstractProductName = (abstractProductId: number) => {
    const ap = abstractProductSelectOptions.find((a) => a.value === abstractProductId.toString());
    if (ap) {
      return ap.label;
    }

    return 'Unknown abstract product';
  };

  const getAbstractProductSelectOption = (abstractProductId: number) => {
    const ap = abstractProductSelectOptions.find((a) => a.value === abstractProductId.toString());
    if (ap) {
      return ap;
    }

    return abstractProductSelectOptions[0];
  };

  const fetchAbstractProductList = async () => {
    const listResponse = await apiRequest(
      ApiEndpoint.abstractProductV1List,
      {},
      'data',
      'GET',
    ) as AbstractProductListV1Response;

    const items: SelectOption[] = [];

    listResponse.list.forEach((ap) => {
      if (ap.abstractProductId !== currentAbstractProductId) {
        items.push({
          value: ap.abstractProductId.toString(),
          label: ap.name,
        });
      }
    });

    setAbstractProductSelectOptions(items);
  };

  const fetchData = async (abstractProductId: number) => {
    setIsLoading(true);
    setIsDataFetched(false);
    try {
      await fetchMainAbstractProduct(abstractProductId);
      await fetchBundles(abstractProductId);
      await fetchAbstractProductList();
      setIsDataFetched(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error during data fetching');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(Number(params.abstractProductId));
  }, [currentAbstractProductId]);

  useEffect(() => {
    if (isDataFetched) {
      const selectedAbstractProductsForGroupsTmp: SelectOption[] = [];
      bundles.groups.forEach((i) => {
        selectedAbstractProductsForGroupsTmp.push(abstractProductSelectOptions[0]);
      });
      setSelectedAbstractProductsForGroups(selectedAbstractProductsForGroupsTmp);
      setIsLoading(false);
    }
  }, [currentAbstractProductId, isDataFetched]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.abstractProductList));
  };

  const onRemoveGroupClick = (groupIndex: number) => {
    const newBundles = cloneDeep(bundles);
    newBundles.groups.splice(groupIndex, 1);
    setBundles(newBundles);

    const selectedProducts = cloneDeep(selectedAbstractProductsForGroups);
    selectedProducts.splice(groupIndex, 1);
    setSelectedAbstractProductsForGroups(selectedProducts);
  };

  const onGroupLabelTextChange = (groupIndex: number, labelTranslationKey: string) => {
    const newBundles = cloneDeep(bundles);
    const group = newBundles?.groups[groupIndex];
    if (group) {
      group.labelTranslationKey = labelTranslationKey;
      setBundles(newBundles);
    }
  };

  const onGroupProductTypeChange = (groupIndex: number, productTypeGroup: string) => {
    const newBundles = cloneDeep(bundles);
    const group = newBundles?.groups[groupIndex];
    if (group) {
      group.productTypeGroup = productTypeGroup;
      setBundles(newBundles);
    }
  };

  const onRemoveProductClick = (groupIndex: number, itemIndex: number) => {
    const newBundles = cloneDeep(bundles);
    newBundles?.groups[groupIndex]?.bundleItems?.splice(itemIndex, 1);
    setBundles(newBundles);
  };

  const onAddGroupClick = () => {
    const newBundles = cloneDeep(bundles);
    const newGroup: Group = {
      bundleItems: [],
      canBeNotSelected: true,
      canSelectMultiple: false,
      productTypeGroup: productTypeGroupSelectOptions[0].label,
      labelTranslationKey: '',
    };
    newBundles.groups.push(newGroup);
    setBundles(newBundles);

    const selectedProducts = cloneDeep(selectedAbstractProductsForGroups);
    selectedProducts.push(abstractProductSelectOptions[0]);
    setSelectedAbstractProductsForGroups(selectedProducts);
  };

  const onAddProductClick = (groupIndex: number) => {
    const selectedOption = selectedAbstractProductsForGroups[groupIndex];
    const abstractProductId = Number(selectedOption.value);

    fetchAbstractProductUidMappings([abstractProductId]);

    const newBundles = cloneDeep(bundles);
    const newProduct: BundleItem = { abstractProductId, mapping: [] };
    newBundles?.groups[groupIndex]?.bundleItems?.push(newProduct);
    setBundles(newBundles);
  };

  const onSelectedAbstractProductForGroupChange = (groupIndex: number, abstractProductId: number) => {
    const selectedProducts = cloneDeep(selectedAbstractProductsForGroups);
    selectedProducts.splice(groupIndex, 1, getAbstractProductSelectOption(abstractProductId));
    setSelectedAbstractProductsForGroups(selectedProducts);
  };

  const onCanSelectMultipleClick = (groupIndex: number, value: boolean) => {
    const newBundles = cloneDeep(bundles);
    newBundles.groups[groupIndex].canSelectMultiple = value;
    setBundles(newBundles);
  };

  const onCanBeNotSelectedClick = (groupIndex: number, value: boolean) => {
    const newBundles = cloneDeep(bundles);
    newBundles.groups[groupIndex].canBeNotSelected = value;
    setBundles(newBundles);
  };

  const onOpenChildProductSelector = (
    groupIndex: number,
    itemIndex: number,
    parentProductId: number,
    bundledAbstractProductId: number,
  ) => {
    const selectorData = {
      groupIndex, itemIndex, parentProductId, bundledAbstractProductId,
    };
    const selectOptions = uidMapping[selectorData?.bundledAbstractProductId].map((p) => (
      {
        value: p.productId.toString(),
        label: p.internalUid,
      } as SelectOption
    ));

    setSelectorOptionsForChildProducts(selectOptions);
    setSelectedBundledProductOption(selectOptions[0] ?? null);
    setOpenedBundledItemSelector(selectorData);
  };

  const onCloseChildProductSelector = () => {
    setOpenedBundledItemSelector(null);
  };

  const onMappingRemoveClick = (groupIndex: number, itemIndex: number, parentProductId: number, bundledProductIdToRemove: number) => {
    const newBundles = cloneDeep(bundles);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const element = newBundles?.groups[groupIndex]?.bundleItems[itemIndex] ?? null;
    if (element) {
      element.mapping?.forEach((map) => {
        if (map.parentProductId === parentProductId) {
          const index = map.bundledProductIds?.indexOf(bundledProductIdToRemove);
          if (index !== undefined && index !== -1) {
            map.bundledProductIds?.splice(index, 1);
          }
        }
      });
    }
    setBundles(newBundles);
  };

  const onBundledProductIdSaveClick = () => {
    if (!openedBundledItemSelector) {
      return;
    }

    const bundledProductId = Number(selectedBundledProductOption?.value);

    const newBundles = cloneDeep(bundles);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mapping = newBundles?.groups[openedBundledItemSelector.groupIndex]?.bundleItems[openedBundledItemSelector.itemIndex].mapping.find(
      (m) => m.parentProductId === openedBundledItemSelector.parentProductId,
    );
    if (!mapping) {
      const newMapping: Mapping = {
        bundledProductIds: [bundledProductId],
        parentProductId: openedBundledItemSelector.parentProductId,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newBundles?.groups[openedBundledItemSelector.groupIndex]?.bundleItems[openedBundledItemSelector.itemIndex].mapping.push(newMapping);
    } else {
      if (!mapping.bundledProductIds) {
        mapping.bundledProductIds = [];
      }
      mapping.bundledProductIds.push(bundledProductId);
    }

    setBundles(newBundles);
    onCloseChildProductSelector();
  };

  const renderGroups = () => bundles?.groups?.map((group, groupIndex) => (
    <Card
      className="mb-3"
      key={`group_${groupIndex}`}
      title={(
        <div className="row">
          <div className="col-10">{`Sub product ${groupIndex + 1}`}</div>
          <div className="col-2">
            <span
              className="btn btn-danger w-100"
              onClick={() => {
                onRemoveGroupClick(groupIndex);
              }}
            >
              Remove group
            </span>
          </div>
        </div>
      )}
    >
      <div className="row">
        <div className="col-12">
          <div
            className="cursorPointer mb-2"
            onClick={() => {
              onCanBeNotSelectedClick(groupIndex, !group.canBeNotSelected);
            }}
          >
            <input
              type="checkbox"
              checked={group.canBeNotSelected}
              onChange={() => {
              }}
            />
            <span className="form-check-label"> Can be not selected</span>
          </div>
          <div
            className="cursorPointer mb-2"
            onClick={() => {
              onCanSelectMultipleClick(groupIndex, !group.canSelectMultiple);
            }}
          >
            <input
              type="checkbox"
              checked={group.canSelectMultiple}
              onChange={() => {
              }}
            />
            <span className="form-check-label"> Can select multiple</span>
          </div>
          <InputTextControlled
            name={`labelTextKey${groupIndex}`}
            label="Label translation key"
            value={group.labelTranslationKey}
            onChange={(value) => onGroupLabelTextChange(groupIndex, value)}
          />
          <InputSelectControlled
            label="Product type group"
            name={`productTypeGroup${groupIndex}`}
            value={
              productTypeGroupSelectOptions.find((g) => g.value === group.productTypeGroup) ?? productTypeGroupSelectOptions[0]
            }
            onChange={(value: string) => {
              onGroupProductTypeChange(groupIndex, value);
            }}
            options={productTypeGroupSelectOptions}
          />
        </div>
        <div className="col-10">
          <InputSelectControlled
            value={selectedAbstractProductsForGroups[groupIndex]}
            name="productId"
            onChange={(value: string) => {
              onSelectedAbstractProductForGroupChange(groupIndex, Number(value));
            }}
            options={abstractProductSelectOptions}
          />
        </div>
        <div className="col-2">
          <span
            className="btn btn-success w-100"
            onClick={() => {
              onAddProductClick(groupIndex);
            }}
          >
            Add product
          </span>
        </div>
      </div>
      <h5>Products in group:</h5>
      {renderProductsInGroup(groupIndex, group.bundleItems)}
    </Card>
  ));

  const renderProductsInGroup = (groupIndex: number, bundleItems?: IBundleItem[] | null) => bundleItems?.map((bundleItem, itemIndex) => (
    <Card
      key={`item_${itemIndex}`}
      className="mb-3"
      title={(
        <div className="d-flex justify-content-between">
          <span>{`${getAbstractProductName(bundleItem.abstractProductId)} #${bundleItem.abstractProductId}`}</span>
          <span
            className="btn btn-danger"
            onClick={() => {
              onRemoveProductClick(groupIndex, itemIndex);
            }}
          >
            Remove product
          </span>
        </div>
      )}
    >
      {renderMapping(groupIndex, itemIndex, bundleItem.abstractProductId, bundleItem.mapping ?? [])}
    </Card>
  ));

  const renderChildItemSelector = (groupIndex: number, itemIndex: number, bundledAbstractProductId: number, parentProductId: number) => {
    if (!openedBundledItemSelector
      || (
        openedBundledItemSelector.bundledAbstractProductId !== bundledAbstractProductId
        || openedBundledItemSelector.groupIndex !== groupIndex
        || openedBundledItemSelector.itemIndex !== itemIndex
        || openedBundledItemSelector.parentProductId !== parentProductId)
      || !selectedBundledProductOption
    ) {
      return null;
    }

    const onBundledItemChildrenChange = (newValue: string) => {
      const option = selectorOptionsForChildProducts.find((o) => o.value === newValue);
      setSelectedBundledProductOption(option);
    };

    return (
      <div className="row">
        <div className="col-10">
          <InputSelectControlled
            value={selectedBundledProductOption}
            name="bundledProductId"
            onChange={(value: string) => {
              onBundledItemChildrenChange(value);
            }}
            options={selectorOptionsForChildProducts}
          />
        </div>
        <div className="col-2">
          <span
            className="btn btn-success"
            onClick={() => {
              onBundledProductIdSaveClick();
            }}
          >
            Save mapping
          </span>
        </div>
      </div>
    );
  };

  const renderMapping = (
    groupIndex: number,
    itemIndex: number,
    bundledAbstractProductId: number,
    mappingItems: IMapping[],
  ) => mainAbstractProduct.opProducts.map((p, i) => {
    const bundledItems: any = [];
    mappingItems.forEach((mi) => {
      if (mi.parentProductId === p.productId) {
        mi.bundledProductIds?.forEach((bundledProductId) => {
          bundledItems.push(renderMatchingProduct(groupIndex, itemIndex, bundledAbstractProductId, mi.parentProductId, bundledProductId));
        });
      }
    });
    return (
      <div key={`mapping_${groupIndex}_${itemIndex}_${bundledAbstractProductId}_${i}`}>
        <div>
          {p.internalUid}
          &nbsp;
          <span
            className="badge bg-success cursorPointer"
            onClick={() => {
              onOpenChildProductSelector(groupIndex, itemIndex, p.productId, bundledAbstractProductId);
            }}
          >
            +
          </span>
        </div>
        {bundledItems}
        {renderChildItemSelector(groupIndex, itemIndex, bundledAbstractProductId, p.productId)}
        <hr />
      </div>
    );
  });

  const onSaveClick = async () => {
    setIsLoading(true);
    try {
      await apiRequest(
        ApiEndpoint.abstractProductBundlesV1Save,
        bundles,
        'data',
        'POST',
      );
      setIsLoading(false);
      onBackClick();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderMatchingProduct = (
    groupIndex: number,
    itemIndex: number,
    bundledAbstractProductId: number,
    parentProductId: number,
    bundledProductId: number,
  ) => {
    const product = uidMapping[bundledAbstractProductId]?.find((p) => p.productId === bundledProductId);
    if (!product) {
      return null;
    }
    return (
      <div
        className="badge bg-light d-flex mb-2 justify-content-between"
        style={{ fontSize: '16px' }}
        key={`matching_${parentProductId}_${groupIndex}_${itemIndex}_${bundledProductId}_${bundledAbstractProductId}`}
      >
        <span className="font-weight-normal">{product.productUid}</span>
        <span
          className="badge bg-danger cursorPointer"
          onClick={() => {
            onMappingRemoveClick(groupIndex, itemIndex, parentProductId, bundledProductId);
          }}
        >
          X
        </span>
      </div>
    );
  };

  const renderTopControls = () => (
    <FormConfirmButtonsBar
      onBack={() => onBackClick()}
      onSave={() => onSaveClick()}
    />
  );

  return (
    <PageContent
      title={`Edit bundled products for ${mainAbstractProduct?.name ?? ''}`}
      isLoading={isLoading}
    >
      {renderTopControls()}
      <div className="d-flex justify-content-end mb-3">
        <span
          className="btn btn-success"
          onClick={() => {
            onAddGroupClick();
          }}
        >
          Add group
        </span>
      </div>
      {renderGroups()}
    </PageContent>
  );
};
export default AbstractProductBundles;
