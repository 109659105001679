export interface IntegrationLayer {
  apiBaseUrl: string;
  locales: string[];
}

declare global {
  interface Window {
    dataLayer: any[];
    integrationLayer: IntegrationLayer;
  }
}

export enum LocaleEnum {
  'enXX' = 'en-XX',
  'enCA' = 'en-CA',
  'enGB' = 'en-GB',
  'enAU' = 'en-AU',
  'enIE' = 'en-IE',
  'enNZ' = 'en-NZ',
  'enUS' = 'en-US',
  'csCZ' = 'cs-CZ',
  'noNO' = 'no-NO',
  'svSE' = 'sv-SE',
  'dkDK' = 'dk-DK',
  'frCA' = 'fr-CA',
  'frFR' = 'fr-FR',
  'frCH' = 'fr-CH',
  'frBE' = 'fr-BE',
  'esES' = 'es-ES',
  'deAT' = 'de-AT',
  'deDE' = 'de-DE',
  'deCH' = 'de-CH',
  'nlBE' = 'nl-BE',
  'nlNL' = 'nl-NL',
  'ptPT' = 'pt-PT',
  'ptBR' = 'pt-BR',
  'plPL' = 'pl-PL',
  'itIT' = 'it-IT',
  'enSG' = 'en-SG',
  'esCL' = 'es-CL',
  'enIN' = 'en-IN',
}
