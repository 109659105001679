import React, { useState } from 'react';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { HttpMethods } from 'src/api/types';
import PageContent from 'src/component/PageContent';
import Spinner from 'src/component/Spinner';

interface ClipArtImportFromGoogleSpreadsheetV1Response {
  log: string;
}

const ClipArtSpreadsheetImport = () => {
  const [spreadsheetId, setSpreadsheetId] = useState('');
  const [log, setLog] = useState('');
  const [loading, setLoading] = useState(false);

  const importClipArt = async () => {
    try {
      setLoading(true);
      const response = await apiRequest(
        ApiEndpoint.clipArtImportFromGoogleSpreadsheet,
        {
          spreadsheetId,
        },
        'data',
        HttpMethods.POST,
      ) as ClipArtImportFromGoogleSpreadsheetV1Response;
      setLog(response.log);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during importing clip art', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContent title="Import clip art from spreadsheet">
      <div className="row mb-3">
        <div className="col-6 text-left">
          <input
            type="text"
            value={spreadsheetId}
            className="form-control"
            placeholder="Spreadsheet ID"
            onChange={(e) => setSpreadsheetId(e.target.value)}
          />
        </div>
        <div className="col-6">
          <button className="btn btn-success" onClick={importClipArt} disabled={!spreadsheetId}>
            {loading ? (<Spinner size={15} />) : 'Import'}
          </button>
        </div>
      </div>
      {log && (
        <div className="row mb-3 justify-content-md-center">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Log</h5>
                {log}
              </div>
            </div>
          </div>
        </div>
      )}

    </PageContent>
  );
};

export default ClipArtSpreadsheetImport;
