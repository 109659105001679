import React, { useState } from 'react';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { HttpMethods } from 'src/api/types';
import PageContent from 'src/component/PageContent';
import Spinner from 'src/component/Spinner';
import { toast } from 'react-toastify';

const ClipArtSortOrderImport = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const showSuccessMessage = () => {
    toast.success(
      'Sucessfully imported clip art sort order',
      {
        position: toast.POSITION.TOP_RIGHT,
      },
    );
  };

  const importSortOrder = async () => {
    try {
      setLoading(true);
      await apiRequest(
        ApiEndpoint.clipArtSortOrderImport,
        {
          url,
        },
        'data',
        HttpMethods.POST,
      );
      showSuccessMessage();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while importing clip art sort order', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContent title="Import clip art sort order">
      <div className="row mb-3">
        <div className="col-6 text-left">
          <input
            type="text"
            value={url}
            className="form-control"
            placeholder="Google document URL"
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="col-6">
          <button className="btn btn-success" onClick={importSortOrder} disabled={!url}>
            {loading ? (<Spinner size={15} />) : 'Import'}
          </button>
        </div>
      </div>
    </PageContent>
  );
};

export default ClipArtSortOrderImport;
