import React from 'react';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { getUrl, RouteName } from 'src/routing';

interface WithProperties {
  selected?: boolean;
  isOutdatedText: string;
  isRemovedText: string;
}

export interface OwnProps {
  item: AdminBundle.Entity.API.ContentfulSyncControllerV1.IContentfulSync & WithProperties;
  deleteAction: (id: number) => void;
  selectable: boolean;
  selectAction: (row: AdminBundle.Entity.API.ContentfulSyncControllerV1.IContentfulSync, e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContentfulSyncItem = ({
  item,
  deleteAction,
  selectable,
  selectAction,
}: OwnProps) => {
  const deleteItem = async () => {
    deleteAction(+item.id);
  };
  return (
    <tr key={item.id}>
      {selectable && <td><input type="checkbox" checked={item.selected || false} onChange={(e) => selectAction(item, e)} /></td>}
      <td>{item.id}</td>
      <td>{item.contentType}</td>
      <td>{item.lastSyncOn}</td>
      <td>{item.isOutdatedText}</td>
      <td>{item.lastSyncStatus}</td>
      <td>{item.errorMessage}</td>
      <td>{item.isRemovedText}</td>
      <td>{item.updatedAt}</td>
      <td>
        <div className="btn-group" role="group">
          <Link
            to={getUrl(RouteName.contentfulSyncEdit, { id: item.id })}
            className="btn btn-sm btn-warning"
          >
            Edit
          </Link>
          <button className="btn btn-sm btn-danger" onClick={deleteItem}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ContentfulSyncItem;
