import React, { useState } from 'react';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputCheckbox from 'src/component/Input/InputCheckbox';
import InputNumber from 'src/component/Input/InputNumber';
import InputSelect from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import { Form, Formik } from 'formik';
import requiredValidator from 'src/validator/requiredValidator';
import requiredIntegerValidator from 'src/validator/requiredIntegerValidator';
import { FieldTypes, FormConfig, FormFieldConfig } from '../types';

export interface OwnProps {
  isBulkEditing?: boolean;
  formConfig: FormConfig;
  editedItem: any;
  onSubmit: (values: any) => void;
  onBackClick?: () => void;
}

export const renderFields = (fields: FormFieldConfig[], editableProperties: { [key: string]: boolean }) => (
  <div>
    {fields.map((field) => {
      if (field.type === FieldTypes.InputTextField) {
        return (
          <InputText
            key={field.name}
            name={field.name}
            label={field.label}
            validate={field.required ? requiredValidator : undefined}
            disabled={!editableProperties[field.name]}
          />
        );
      }
      if (field.type === FieldTypes.InputSelectField) {
        return (
          <InputSelect
            key={field.name}
            name={field.name}
            options={field.options || []}
            label={field.label}
            disabled={!editableProperties[field.name]}
          />
        );
      }
      if (field.type === FieldTypes.InputCheckboxField) {
        return (
          <InputCheckbox
            key={field.name}
            name={field.name}
            label={field.label}
            disabled={!editableProperties[field.name]}
          />
        );
      }
      if (field.type === FieldTypes.InputNumberField) {
        return (
          <InputNumber
            key={field.name}
            name={field.name}
            label={field.label}
            validate={field.required ? requiredIntegerValidator : undefined}
            disabled={!editableProperties[field.name]}
          />
        );
      }
      return null;
    })}
  </div>
);

const CRUDTemplateForm = ({
  formConfig,
  editedItem,
  onSubmit,
  onBackClick,
  isBulkEditing = false,
}: OwnProps) => {
  const editable = { ...editedItem };
  Object.keys(editedItem).forEach((key) => {
    editable[key] = !isBulkEditing;
  });

  const [editableProperties, setEditableProperties] = useState(editable);

  const updateEditableProperties = (fieldName: string, checked: boolean) => {
    const newEditablePropeties = {
      ...editableProperties,
    };
    newEditablePropeties[fieldName] = checked;
    setEditableProperties(newEditablePropeties);
  };

  const handleOnSubmit = (values: any) => {
    if (!isBulkEditing) {
      onSubmit(values);
    } else {
      Object.keys(editableProperties).forEach((key) => {
        if (!editableProperties[key]) {
          delete values[key];
        }
      });
      onSubmit(values);
    }
  };

  return (
    <>
      <Formik
        initialValues={editedItem}
        onSubmit={(values) => handleOnSubmit(values)}
      >
        {({ values }) => (
          <Form>
            <Card className="mb-3">
              {isBulkEditing ? (
                <div className="row">
                  <div className="col-2">
                    <p className="h5 mb-3">Fields to update</p>
                    {formConfig.fields.map((field) => (
                      <div className="form-check" key={`${field.name}_form_check`}>
                        <input
                          type="checkbox"
                          checked={editableProperties[field.name]}
                          onChange={(e) => updateEditableProperties(field.name, e.target.checked)}
                          className="form-check-input"
                          id={field.label}
                        />
                        <label className="form-check-label" htmlFor={field.label}>{field.label}</label>
                      </div>
                    ))}
                  </div>
                  <div className="col-10">
                    {renderFields(formConfig.fields, editableProperties)}
                  </div>
                </div>
              ) : renderFields(formConfig.fields, editableProperties)}
            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CRUDTemplateForm;
