import * as yup from 'yup';

const integerValidator = (value: number | string | boolean | null | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }

  const schema = yup.number().integer();
  if (!schema.isValidSync(value)) {
    return 'Must be an integer!';
  }
  return undefined;
};

export default integerValidator;
