import integerValidator from 'src/validator/integerValidator';
import requiredValidator from 'src/validator/requiredValidator';

const requiredIntegerValidator = (value: number | string | boolean | null | undefined): string | undefined => {
  const required = requiredValidator(value);
  if (required) {
    return required;
  }
  const integer = integerValidator(value);
  if (integer) {
    return integer;
  }
  return undefined;
};

export default requiredIntegerValidator;
