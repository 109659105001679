/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import CRUDTemplate from 'src/component/CRUDTemplate';
import { getUrl, RouteName } from 'src/routing';
import { fetchProductDesignGenerationCandidates, deleteProductDesignGenerationCandidate } from 'src/service/AbstractProductDesignGenerationCandidate';
import ProductDesignGenerationCandidateItem from './ProductDesignGenerationItem/index';
import { editForm, tableHeaders } from './config';

const ProductDesignGenerationCandidates = () => {
  // eslint-disable-next-line max-len
  const [productGenerationCandidates, setProductGenerationCandidates] = useState([] as AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.IAbstractProductDesignGenerationCandidate[]);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line max-len
      const response = await fetchProductDesignGenerationCandidates() as AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateListV1Response;
      setProductGenerationCandidates(response.candidates);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeletion = async () => {
    try {
      await deleteProductDesignGenerationCandidate(idToDelete);
      await fetchData();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const initiateDelete = (id: number) => {
    setIdToDelete(id);
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure?')) {
      confirmDeletion();
    }
  };

  const onBulkEdit = async (editedItem: any) => {
    // TODO: implement request when Candidate API is ready
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <CRUDTemplate<AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.IAbstractProductDesignGenerationCandidate>
        items={productGenerationCandidates}
        tableHeaders={tableHeaders}
        title="Product design generation candidates"
        loading={loading}
        showDeleteButton
        showEditButton
        TableRowComponent={ProductDesignGenerationCandidateItem}
        addNewRoute={getUrl(RouteName.abstractProductDesignGenerationCandidateAdd)}
        deleteAction={initiateDelete}
        allowSelection
        bulkEditConfig={editForm}
        bulkEditAction={onBulkEdit}
      />
    </div>
  );
};

export default ProductDesignGenerationCandidates;
