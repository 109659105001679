enum ApiEndpoint {
  // Url mapping
  'urlMappingV1Get' = '/admin/api/url-mapping/v1/get',
  'urlMappingV1List' = '/admin/api/url-mapping/v1/list',
  'urlMappingV1Save' = '/admin/api/url-mapping/v1/save',

  // Category
  'categoryV1Get' = '/admin/api/category/v1/get',
  'categoryV1Save' = '/admin/api/category/v1/save',
  'categoryV1List' = '/admin/api/category/v1/list',

  // Clip Art Category
  'clipArtCategoryV1List' = '/admin/api/clip-art-category/v1/list',
  'clipArtCategoryV1Add' = '/admin/api/clip-art-category/v1/add',
  'clipArtCategoryV1Edit' = '/admin/api/clip-art-category/v1/edit',
  'clipArtCategoryV1Delete' = '/admin/api/clip-art-category/v1/delete',
  'clipArtCategoryV1GetTranslations' = '/admin/api/clip-art-category/v1/get-translations',

  // Clip Art Price Tier
  'clipArtPriceTierV1List' = '/admin/api/clip-art-price-tier/v1/list',
  'clipArtPriceTierV1Add' = '/admin/api/clip-art-price-tier/v1/add',
  'clipArtPriceTierV1GetPriceSheet' = '/admin/api/clip-art-price-tier/v1/get-price-sheet',
  'clipArtV1GetUploadReport' = '/admin/api/clip-art/v1/get-upload-report',

  // Clip Art
  'clipArtV1Add' = '/admin/api/clip-art/v1/add',
  'clipArtImportFromGoogleSpreadsheet' = '/admin/api/clip-art/v1/import-from-google-spreadsheet',
  'clipArtSortOrderImport' = '/admin/api/clip-art/v1/import-sort-order-from-url',

  // Landing page category
  'landingPageCategoryV1Get' = '/admin/api/landing-page-category/v1/get',
  'landingPageCategoryV1Save' = '/admin/api/landing-page-category/v1/save',
  'landingPageCategoryV1List' = '/admin/api/landing-page-category/v1/list',

  // Product type
  'productTypeV1Get' = '/admin/api/product-type/v1/get',
  'productTypeV1Add' = '/admin/api/product-type/v1/add',
  'productTypeV1Edit' = '/admin/api/product-type/v1/edit',
  'productTypeV1List' = '/admin/api/product-type/v1/list',

  // Product attribute type
  'attributeTypeV1Get' = '/admin/api/attribute-type/v1/get',
  'attributeTypeV1Add' = '/admin/api/attribute-type/v1/add',
  'attributeTypeV1Edit' = '/admin/api/attribute-type/v1/edit',
  'attributeTypeV1List' = '/admin/api/attribute-type/v1/list',

  // Product attribute
  'attributeV1Get' = '/admin/api/attribute/v1/get',
  'attributeV1Add' = '/admin/api/attribute/v1/add',
  'attributeV1Edit' = '/admin/api/attribute/v1/edit',
  'attributeV1List' = '/admin/api/attribute/v1/list',

  // Abstract product
  'abstractProductV1Get' = '/admin/api/abstract-product/v1/get',
  'abstractProductV1Add' = '/admin/api/abstract-product/v1/add',
  'abstractProductV1Edit' = '/admin/api/abstract-product/v1/edit',
  'abstractProductV1List' = '/admin/api/abstract-product/v1/list',
  'abstractProductV1GetPriceSheet' = '/admin/api/abstract-product/v1/get-price-sheet',
  'abstractProductDesignGenerationCandidateList' = '/admin/api/abstract-product-design-generation-candidate/v1/list',
  'abstractProductDesignGenerationCandidateAdd' = '/admin/api/abstract-product-design-generation-candidate/v1/add',
  'abstractProductDesignGenerationCandidateDelete' = '/admin/api/abstract-product-design-generation-candidate/v1/delete',
  'abstractProductDesignGenerationCandidateEdit' = '/admin/api/abstract-product-design-generation-candidate/v1/edit',
  'abstractProductDesignGenerationCandidate' = '/admin/api/abstract-product-design-generation-candidate/v1/get',

  // Contentful Sync
  'contentfulSyncV1Add' = '/admin/api/contentful-sync/v1/add',
  'contentfulSyncV1BulkEdit' = '/admin/api/contentful-sync/v1/bulk-edit',
  'contentfulSyncV1Delete' = '/admin/api/contentful-sync/v1/delete',
  'contentfulSyncV1Edit' = '/admin/api/contentful-sync/v1/edit',
  'contentfulSyncV1Get' = '/admin/api/contentful-sync/v1/get',
  'contentfulSyncV1List' = '/admin/api/contentful-sync/v1/list',

  // Abstract product attributes list
  'abstractProductAttributesV1Add' = '/admin/api/abstract-product-attribute/v1/add',
  'abstractProductAttributesV1Remove' = '/admin/api/abstract-product-attribute/v1/remove',
  'abstractProductAttributesV1List' = '/admin/api/abstract-product-attribute/v1/list',

  // Abstract product translation pattern
  'abstractProductTranslationPatternCollectionV1Get' = '/admin/api/abstract-product/translation-pattern/v1/collection/get',
  'abstractProductTranslationPatternCollectionV1Edit' = '/admin/api/abstract-product/translation-pattern/v1/collection/edit',

  // Abstract product translation pattern places
  'abstractProductTranslationPatternV1Places' = '/admin/api/abstract-product/translation-pattern/v1/places',

  // Gelato api catalog
  'gelatoApiCatalogV1CatalogList' = '/admin/api/gelato-api-catalog/v1/catalog/list',
  'gelatoApiCatalogV1ProductsSearch' = '/admin/api/gelato-api-catalog/v1/products/search',

  // Abstract product translation pattern places
  'abstractProductV1AvailableLocales' = '/admin/api/abstract-product/v1/available-locales',

  // Abstract product country availability
  'abstractProductV1AllCountries' = '/admin/api/abstract-product/v1/all-countries',
  'abstractProductCountryAvailailityV1List' = '/admin/api/abstract-product/country-availability/v1/list',
  'abstractProductCountryAvailailityV1Edit' = '/admin/api/abstract-product/country-availability/v1/edit',

  // Abstract product bundles
  'abstractProductBundlesV1List' = '/admin/api/abstract-product/bundle/v1/list',
  'abstractProductBundlesV1Save' = '/admin/api/abstract-product/bundle/v1/save',
}

export default ApiEndpoint;
