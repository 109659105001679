import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import { HttpMethods } from 'src/api/types';

export const fetchProductDesignGenerationCandidates = () => apiRequest(
  ApiEndpoint.abstractProductDesignGenerationCandidateList,
  {},
  'data',
);

export const fetchProductDesignGenerationCandidateById = (
  id: number,
) => apiRequest(
  ApiEndpoint.abstractProductDesignGenerationCandidate,
  {
    id,
  },
  'data.candidate',
);

export const deleteProductDesignGenerationCandidate = (id: number) => apiRequest(
  ApiEndpoint.abstractProductDesignGenerationCandidateDelete,
  { id },
  'data',
  HttpMethods.POST,
);

export const addProductDesignGenerationCandidate = (
  values: AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateAddV1Request,
) => apiRequest(
  ApiEndpoint.abstractProductDesignGenerationCandidateAdd,
  values,
  'data',
  HttpMethods.POST,
);

export const updateProductDesignGenerationCandidate = (
  values: AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.AbstractProductDesignGenerationCandidateEditV1Request,
) => apiRequest(
  ApiEndpoint.abstractProductDesignGenerationCandidateEdit,
  { candidate: values },
  'data',
  HttpMethods.POST,
);
