import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageContent from 'src/component/PageContent';
import filterArrayByCriteria from 'src/util/helper/filterArrayByCriteria';
import useRowSelection from 'src/util/hook/useRowSelection';
import ItemsListSearchInput from '../ItemsListSearchInput';
import CRUDTemplateForm from './CRUDTemplateForm';
import { FormConfig, TableHeader } from './types';

const NUM_ITEMS_TO_DISPLAY = 100;
export interface OwnProps<T> {
  title: string;
  items: T[];
  tableHeaders: TableHeader[];
  loading: boolean;
  showEditButton: boolean;
  showDeleteButton: boolean;
  TableRowComponent: any;
  addNewRoute: string;
  deleteAction: (id: number) => void;
  allowSelection?: boolean;
  bulkEditAction?: (values: any, selectedIds: [number | string]) => void;
  bulkEditConfig?: FormConfig;
}

const CRUDTemplate = <T extends { id?: number | string; selected?: boolean }, >({
  title,
  items,
  tableHeaders,
  loading,
  showEditButton,
  showDeleteButton,
  TableRowComponent,
  addNewRoute,
  deleteAction,
  allowSelection = false,
  bulkEditAction,
  bulkEditConfig,
}: OwnProps<T>) => {
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showBulkEditForm, setShowBulkEditForm] = useState(false);
  const [bulkEditedItem, setBulkEditedItem] = useState({});
  const [currentItemSlice, setCurrentItemSlice] = useState(0);
  const [displayedItems, setDisplayedItems] = useState([] as T[]);
  const [filteredItems, setFilteredItems] = useState([] as T[]);

  const {
    selectedRows,
    setSelectedRows,
    allSelected,
    onElementSelect,
    onMasterSelect,
  } = useRowSelection(displayedItems);

  useEffect(() => {
    setShowBulkEditForm(!!selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    const editedItem = {};
    bulkEditConfig?.fields.map((field) => field.name).forEach((fieldName) => {
      Object.assign(editedItem, { [fieldName]: '' });
    });
    setBulkEditedItem(editedItem);
  }, []);

  useEffect(() => {
    setFilteredItems(items);
    setDisplayedItems(items.slice(0, NUM_ITEMS_TO_DISPLAY));
  }, [items]);

  useEffect(() => {
    setShowBulkEditForm(false);
    setSelectedRows([]);
    if (search === '') {
      setFilteredItems(removeItemSelection(items));
      setDisplayedItems(removeItemSelection(items.slice(0, NUM_ITEMS_TO_DISPLAY)));
    } else {
      const filtered = filterArrayByCriteria<T>(items, search);
      setFilteredItems(removeItemSelection(filtered));
      setDisplayedItems(removeItemSelection(filtered.slice(0, NUM_ITEMS_TO_DISPLAY)));
    }
    setCurrentItemSlice(0);
  }, [search]);

  const showNextItemSlice = () => {
    const nextSliceIndex = currentItemSlice + 1;
    const nextSlice = filteredItems.slice(nextSliceIndex * NUM_ITEMS_TO_DISPLAY, (nextSliceIndex + 1) * NUM_ITEMS_TO_DISPLAY);
    setDisplayedItems([...displayedItems, ...nextSlice]);
    setCurrentItemSlice(nextSliceIndex);
  };

  const removeItemSelection = (array: T[]) => array.map((item) => {
    delete item.selected;
    return item;
  });

  const templateFormSubmit = (values: any) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Are you sure?')) {
      bulkEditAction && bulkEditAction(values, selectedRows.map((item) => item.id) as [number | string]);
      setShowBulkEditForm(false);
      setSelectedRows([]);
    }
  };

  return (
    <PageContent title={title} isLoading={loading}>
      <ItemsListSearchInput onSearch={setSearch} onChange={setSearchInputValue} currentValue={searchInputValue} />
      <div className="row mb-3">
        <div className="col-12 text-right">
          <Link className="btn btn-success" to={addNewRoute}>Add new</Link>
        </div>
      </div>
      {showBulkEditForm && (
        <CRUDTemplateForm
          isBulkEditing
          editedItem={bulkEditedItem}
          formConfig={bulkEditConfig || { fields: [] }}
          onSubmit={templateFormSubmit || (() => {})}
        />
      )}
      <table className="table">
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            {allowSelection && <th key="selection" scope="col"><input type="checkbox" checked={allSelected} onChange={onMasterSelect} /></th>}
            {tableHeaders.map((header) => (<th key={header.title} style={{ width: header.width }} scope="col">{header.title}</th>))}
            {(showEditButton || showDeleteButton) ? <th>Actions</th> : null}
          </tr>
        </thead>
        <tbody>
          {displayedItems.map((item) => (
            <TableRowComponent<T> selectable={allowSelection} deleteAction={deleteAction} selectAction={onElementSelect} key={item.id} item={item} />
          ))}
        </tbody>
      </table>
      <button className="btn btn-secondary" onClick={showNextItemSlice}>Show more</button>
    </PageContent>
  );
};

export default CRUDTemplate;
