import { Field, useField } from 'formik';
import React from 'react';
import InputDescription from 'src/component/Input/InputDescription';
import InputError from 'src/component/Input/InputError';
import Select from 'react-select';
import InputLabel from 'src/component/Input/InputLabel';

export interface SelectOption {
  value: string | undefined;
  label: string;
}

interface OwnProps {
  label?: string;
  name: string;
  description?: string;
  options: SelectOption[];
  disabled?: boolean;
  multiple?: boolean;
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

interface Props extends OwnProps {
  validate?: (value: number | string | boolean | null | undefined) => string | undefined;
}

const InputSelect = (props: Props) => {
  const [field, meta, helpers] = useField(props);
  const {
    name,
    description,
    label,
    options,
    validate,
    disabled,
    multiple,
  } = props;

  const { value } = meta;
  const { setValue } = helpers;

  const inputId = `inputSelect_${name}`;

  let defaultOption = null;

  if (value) {
    defaultOption = options.find((o) => o.value === value.toString());
  } else if (options.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    defaultOption = options[0];
  }

  return (
    <div className="form-group">
      <InputLabel forInput={inputId} text={label} />
      <Select
        options={options}
        name={field.name}
        isMulti={multiple}
        isDisabled={disabled}
        defaultValue={defaultOption}
        onChange={(option: any) => setValue(option.value)}
        instanceId={inputId}
      />
      <Field as="hidden" name={name} validate={validate} />
      <InputError name={name} />
      <InputDescription description={description} />
    </div>
  );
};

export default InputSelect;
