import { FieldTypes, FormConfig, TableHeader } from 'src/component/CRUDTemplate/types';

const CONTENT_TYPE_LABEL = 'Content Type';
const LAST_SYNC_ON_LABEL = 'Last Sync On';

export const tableHeaders: TableHeader[] = [
  {
    title: 'ID',
  },
  {
    title: CONTENT_TYPE_LABEL,
  },
  {
    title: LAST_SYNC_ON_LABEL,
  },
  {
    title: 'Is Outdated',
  },
  {
    title: 'Last Sync Status',
  },
  {
    title: 'Error Message',
  },
  {
    title: 'Is Removed',
  },
  {
    title: 'Updated At',
  },
];

export const bulkEditForm: FormConfig = {
  fields: [
    {
      name: 'contentType',
      label: CONTENT_TYPE_LABEL,
      type: FieldTypes.InputTextField,
    },
    {
      name: 'isOutdated',
      label: 'Is Outdated',
      type: FieldTypes.InputCheckboxField,
    },
    {
      name: 'lastSyncStatus',
      label: 'Last Sync Status',
      type: FieldTypes.InputTextField,
    },
    {
      name: 'isRemoved',
      label: 'Is Removed',
      type: FieldTypes.InputCheckboxField,
    },
  ],
};
