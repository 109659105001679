import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputSelect, { SelectOption } from 'src/component/Input/InputSelect';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import {
  Formik, Form, FieldArray,
} from 'formik';
import requiredValidator from 'src/validator/requiredValidator';
import InputCheckbox from 'src/component/Input/InputCheckbox';
import InputLabel from 'src/component/Input/InputLabel';
import unset from 'lodash/unset';

type ClipArtCategory = AdminBundle.Entity.API.ClipArtCategoryControllerV1.ClipArtCategoryListV1Response.ClipArtCategory;
type ClipArt = AdminBundle.Entity.API.ClipArtControllerV1.ClipArtAddV1Request;

interface ClipArtFile {
  fileName: string;
  imageUrl: string;
}

// enum FileUploadState {
//   uploaded,
//   inprogress,
// }

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const ClipArtEdit = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const emptyClipArt: ClipArt = {
    theme: '',
    title: '',
    fileName: '',
    imageUrl: '',
    clipArtPriceTierId: 0,
    shutterstockImageId: '',
    description: '',
    tags: [],
    allowColorChange: false,
    clipArtCategoryIds: [],
  };
  const [
    clipArtValues,
    setClipArtValues,
  ] = useState<ClipArt>(emptyClipArt);
  const [categorySelectOptionsWithId, setCategorySelectOptionsWithId] = useState<SelectOption[]>([]);
  const [priceTierOptions, setPriceTierOptions] = useState<SelectOption[]>([]);
  const [clipArtFileData, setClipArtFileData] = useState<ClipArtFile>({ fileName: '', imageUrl: '' });
  const [title, setTitle] = useState('Add new clip art');
  const [subTitle, setSubTitle] = useState('');
  const params = useParams<{ categoryId: string }>();

  const { categoryId } = params;
  const fetchData = async (catId?: string) => {
    setIsLoading(true);
    if (catId) {
      setTitle('Edit clip art');
      setSubTitle('');
    }

    const catgoryToOption = (clipArtCategory: ClipArtCategory, level = 0) => (
      {
        value: String(clipArtCategory.clipArtCategoryId),
        label: '••'.repeat(level) + clipArtCategory.title,
      } as SelectOption
    );

    const flatClipArtCategories = (categories: ClipArtCategory[], level = 0) => {
      const flatList: SelectOption[] = [];
      categories.forEach((clipArtCategory: ClipArtCategory) => {
        flatList.push(catgoryToOption(clipArtCategory, level));
        if (clipArtCategory.subCategories.length) {
          flatList.push(
            ...flatClipArtCategories(clipArtCategory.subCategories as ClipArtCategory[], level + 1),
          );
        }
      });
      return flatList;
    };

    try {
      const categoriesResp = await apiRequest(
        ApiEndpoint.clipArtCategoryV1List,
        {},
        'data.list',
        'POST',
      ) as ClipArtCategory[];
      const categoryList: SelectOption[] = flatClipArtCategories(categoriesResp);
      const optionsWithId = categoryList.map(
        (c) => ({
          value: c.value,
          label: `${c.label} (${c.value})`,
        }),
      );
      setCategorySelectOptionsWithId(optionsWithId);

      const priceTierList = await apiRequest(
        ApiEndpoint.clipArtPriceTierV1List,
        {},
        'data.data',
        'POST',
      ) as AdminBundle.Entity.API.ClipArtPriceTierControllerV1.ClipArtPriceTierListV1Response.PriceTier[];
      setPriceTierOptions(priceTierList.map(
        (pt) => ({
          value: String(pt.clipArtPriceTierId),
          label: pt.title,
        }),
      ));
      setClipArtValues({
        ...clipArtValues,
        clipArtPriceTierId: priceTierList[0].clipArtPriceTierId,
      });

      if (catId) {
        const singleCategoryResp = await apiRequest(
          ApiEndpoint.categoryV1Get,
          {
            categoryId: catId,
          },
          'data',
        ) as AdminBundle.Entity.API.CategoryControllerV1.ICategoryGetV1Response;

        setSubTitle(singleCategoryResp.name);
        // setCategory(singleCategoryResp);
      } else {
        // setCategory(emptyCategory);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(categoryId);
  }, [categoryId]);

  // const onSuccessUpload = (data: any) => {
  //   setClipArtFileData({
  //     fileName: data.file.name,
  //     imageUrl: data.url,
  //   });
  // };

  const onBackClick = () => {
    history.push(getUrl(RouteName.clipArtCategoryList));
  };

  const openUploader = () => {};

  const removeFile = () => {
    setClipArtFileData({
      fileName: '',
      imageUrl: '',
    });
  };

  return (
    <PageContent title={title} subTitle={subTitle} isLoading={isLoading}>
      <Formik
        initialValues={clipArtValues}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          unset(values, 'localeImages');
          values.fileName = clipArtFileData.fileName;
          values.imageUrl = clipArtFileData.imageUrl;
          // console.log(values);
          try {
            await apiRequest(
              ApiEndpoint.clipArtV1Add,
              values,
              'data',
              'POST',
            );
            onBackClick();
          } catch (e) {
            setClipArtValues(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit={Boolean(clipArtFileData.fileName)} />
            <Card header="Clip art category" className="mb-3">
              <InputText
                name="title"
                label="Clip art title"
                validate={requiredValidator}
              />
              <InputText
                name="description"
                label="Clip art description"
              />
              <div className="form-group">
                {!clipArtFileData.fileName && (
                  <div onClick={openUploader} className="btn btn-success">Upload clip art</div>
                )}
                {clipArtFileData.fileName && (
                  <>
                    {clipArtFileData.fileName}
                    <div onClick={() => removeFile()} className="btn btn-success">X</div>
                  </>
                )}
              </div>
              <InputCheckbox
                name="allowColorChange"
                label="Allow color change"
              />
              <InputSelect
                options={priceTierOptions}
                name="clipArtPriceTierId"
                label="Clip art price tier"
              />
              <InputText
                name="shutterstockImageId"
                label="shutterstockImageId"
              />
            </Card>

            <Card header="Clip art categories" className="mb-3">
              <InputSelect
                options={categorySelectOptionsWithId}
                name="clipArtCategoryIds"
                label="Clip art categories"
                validate={requiredValidator}
                multiple
              />
            </Card>

            <Card header="Tags" className="mb-3">
              <FieldArray name="tags">
                {({ remove, push }) => (
                  <>
                    <div className="text-right">
                      <div
                        className="btn btn-success"
                        onClick={
                          () => {
                            push('');
                          }
                        }
                      >
                        Add tag
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-12">
                            <InputLabel text="Tag" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      values.tags?.map((tag, index) => (
                        <div className="row" key={`tag_${index}`}>
                          <div className="col-11">
                            <div className="row">
                              <div className="col-12">
                                <InputText
                                  name={`tags.${index}`}
                                  validate={requiredValidator}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-1">
                            <div
                              className="btn btn-sm btn-danger"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </>
                )}
              </FieldArray>

            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit={Boolean(clipArtFileData.fileName)} />
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

export default ClipArtEdit;
