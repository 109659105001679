import React from 'react';
import { Link } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import EnabledDisabledIcons from 'src/component/EnabledDisabledIcons';
import { getUrl, RouteName } from 'src/routing';

export interface OwnProps {
  item: AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.IAbstractProductDesignGenerationCandidate & { selected?: boolean };
  deleteAction: (id: number) => void;
  selectable: boolean;
  // eslint-disable-next-line max-len
  selectAction: (row: AdminBundle.Entity.API.AbstractProductDesignGenerationCandidateControllerV1.IAbstractProductDesignGenerationCandidate, e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProductDesignGenerationCandidateItem = ({
  item,
  deleteAction,
  selectable,
  selectAction,
}: OwnProps) => {
  const deleteProductDesignGenerationCandidate = async () => {
    deleteAction(item.id);
  };
  return (
    <tr key={item.id}>
      {selectable && <td><input type="checkbox" checked={item.selected || false} onChange={(e) => selectAction(item, e)} /></td>}
      <td>{item.id}</td>
      <td>{item.abstractProductSlug}</td>
      <td>{item.theme}</td>
      <td>{item.suggestedProductUid}</td>
      <td>{item.includeTags}</td>
      <td>{item.excludeTags}</td>
      <td><EnabledDisabledIcons isEnabled={item.enabled} /></td>
      <td>{item.generationType}</td>
      <td>
        <div className="btn-group" role="group">
          <Link
            to={getUrl(RouteName.abstractProductDesignGenerationCandidateEdit, { id: item.id })}
            className="btn btn-sm btn-warning"
          >
            Edit
          </Link>
          <button className="btn btn-sm btn-danger" onClick={deleteProductDesignGenerationCandidate}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ProductDesignGenerationCandidateItem;
