const filterArrayByCriteria = <T, >(array: T[], criteria: string) => {
  let finalFilteredArray = array;
  if (criteria.indexOf(' ') !== -1) {
    const words = criteria.split(' ');
    words.forEach((word) => {
      finalFilteredArray = finalFilteredArray.filter((item: any) => Object.keys(item).some((key) => item[key].toString().indexOf(word) !== -1));
    });
  } else {
    finalFilteredArray = finalFilteredArray.filter((item: any) => Object.keys(item).some((key) => item[key].toString().indexOf(criteria) !== -1));
  }
  return finalFilteredArray;
};

export default filterArrayByCriteria;
