import * as yup from 'yup';

const requireValidator = (value: number | string | boolean | null | undefined): string | undefined => {
  const schema = yup.string().required();
  if (!schema.isValidSync(value)) {
    return 'Field is required!';
  }
  return undefined;
};

export default requireValidator;
