import { useEffect, useState } from 'react';

const useRowSelection = <T>(items: T & { selected?: boolean }[]) => {
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const [allSelected, setAllSelected] = useState(false);

  const onElementSelect = (row: T & { selected?: boolean }, e: React.ChangeEvent<HTMLInputElement>) => {
    row.selected = e.target.checked;
    if (!selectedRows.includes(row)) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((el) => el !== row));
    }
  };

  const onMasterSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllSelected(e.target.checked);
    items.forEach((item) => {
      item.selected = e.target.checked;
    });
    setSelectedRows(items.filter((item) => item.selected));
  };

  useEffect(() => {
    setAllSelected(!!selectedRows.length && selectedRows.length === items.length);
  }, [selectedRows]);

  return {
    selectedRows,
    setSelectedRows,
    allSelected,
    onElementSelect,
    onMasterSelect,
  };
};

export default useRowSelection;
