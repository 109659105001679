import { FieldTypes, FormConfig } from 'src/component/CRUDTemplate/types';

export const addForm: FormConfig = {
  fields: [
    {
      name: 'id',
      label: 'ID',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'contentType',
      label: 'Content Type',
      type: FieldTypes.InputTextField,
      required: true,
    },
  ],
};

export const editForm: FormConfig = {
  fields: [
    {
      name: 'contentType',
      label: 'Content Type',
      type: FieldTypes.InputTextField,
    },
    {
      name: 'lastSyncOn',
      label: 'Last Sync On',
      type: FieldTypes.InputTextField,
    },
    {
      name: 'isOutdated',
      label: 'Is Outdated',
      type: FieldTypes.InputCheckboxField,
    },
    {
      name: 'errorMessage',
      label: 'Error Message',
      type: FieldTypes.InputTextField,
    },
    {
      name: 'isRemoved',
      label: 'Is Removed',
      type: FieldTypes.InputCheckboxField,
    },
  ],
};
