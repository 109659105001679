import { SelectOption } from '../Input/InputSelect';

export interface TableHeader {
  title: string;
  width?: string;
}

export enum FieldTypes {
  InputTextField = 'InputTextField',
  InputSelectField = 'InputSelectField',
  InputCheckboxField = 'InputCheckboxField',
  InputNumberField = 'InputNumberField',
}

export interface FormFieldConfig {
  name: string;
  type: string;
  label: string;
  required?: boolean;
  options?: SelectOption[];
}

export interface FormConfig {
  fields: FormFieldConfig[];
}
