import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiRequest from 'src/api/apiRequest';
import ApiEndpoint from 'src/api/endpoints';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import Card from 'src/component/Card';
import FormConfirmButtonsBar from 'src/component/FormConfirmButtonsBar';
import InputText from 'src/component/Input/InputText';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import {
  Formik, Form, FieldArray,
} from 'formik';
import requiredValidator from 'src/validator/requiredValidator';
import InputLabel from 'src/component/Input/InputLabel';
import { cloneDeep } from 'lodash';

type TranslationPattern = AdminBundle.Entity.API.AbstractProductTranslationPatternControllerV1.AbstractProductTranslationPatternGetV1Response;
// eslint-disable-next-line max-len
type TranslationPatternPlace = AdminBundle.Entity.API.AbstractProductTranslationPatternControllerV1.AbstractProductTranslationPatternPlacesListV1Response.AbstractProductTranslationPatternPlace;
type TranslationPatternPlaceUsed = TranslationPatternPlace & { used?: boolean };

interface FormPatternRow {
  translationPattern: string;
  places: any[];
}

interface FormState {
  patterns: FormPatternRow[];
}

export interface OwnProps {
}

export interface Props extends OwnProps {

}

const AbstractProductTranslationPattern = (props: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFecthed, setIsDataFecthed] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);

  const emptyForm: FormState = {
    patterns: [],
  };

  const [
    formData,
    setFormData,
  ] = useState<FormState>(emptyForm);

  const params = useParams<{ abstractProductId: string }>();
  const [translationPatterns, setTranslationPatterns] = useState<TranslationPattern[]>([]);
  const [translationPatternPlaces, setTranslationPatternPlaces] = useState<TranslationPatternPlaceUsed[]>([]);

  const fetchTranslationPatterns = async (abstractProductId: number) => {
    const translationPatternsRespone = await apiRequest(
      ApiEndpoint.abstractProductTranslationPatternCollectionV1Get,
      { abstractProductId },
      'data.list',
      'POST',
    ) as TranslationPattern[];
    setTranslationPatterns(translationPatternsRespone);
  };

  const fetchTranslationPatternPlaces = async () => {
    const translationPlacesResponse = await apiRequest(
      ApiEndpoint.abstractProductTranslationPatternV1Places,
      { },
      'data.places',
      'GET',
    ) as TranslationPatternPlace[];
    setTranslationPatternPlaces(translationPlacesResponse);
  };

  const fetchData = async (abstractProductId: number) => {
    setIsLoading(true);
    try {
      await fetchTranslationPatterns(abstractProductId);
      await fetchTranslationPatternPlaces();
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error fetching translation patterns data');
      setIsLoading(false);
    } finally {
      setIsDataFecthed(true);
    }
  };

  const prepareFormData = (trPatterns: TranslationPattern[], trPlaces: TranslationPatternPlaceUsed[]) => {
    const result: FormState = cloneDeep(emptyForm);
    const trPlacesUsed = cloneDeep(trPlaces) as TranslationPatternPlaceUsed[];

    const collectionTr: any = {};
    trPatterns.forEach((trPat) => {
      if (!collectionTr[trPat.translationPattern]) {
        collectionTr[trPat.translationPattern] = {
          translationPattern: trPat.translationPattern,
          places: [],
        };
      }
      collectionTr[trPat.translationPattern].places.push(trPat.translationPlace);
      const trPlace = trPlacesUsed.find((tp) => tp.uid === trPat.translationPlace);
      if (trPlace) trPlace.used = true;
    });

    Object.values(collectionTr).forEach((trPat: any) => {
      result.patterns.push({
        translationPattern: trPat.translationPattern,
        places: trPat.places,
      });
    });
    // trPlacesUsed[0].used = false;
    setTranslationPatternPlaces(trPlacesUsed);
    return result;
  };

  const prepareFormDataForSend = (values: FormState) => {
    const result: any[] = [];
    values.patterns.forEach((trPat) => {
      trPat.places.forEach((trPlace) => {
        result.push({
          translationPattern: trPat.translationPattern,
          translationPlace: trPlace,
        });
      });
    });
    return result;
  };

  useEffect(() => {
    fetchData(Number(params.abstractProductId));
  }, []);

  useEffect(() => {
    if (isDataFecthed && !isFormReady) {
      setFormData(prepareFormData(translationPatterns, translationPatternPlaces));
      setIsFormReady(true);
    }
  }, [translationPatterns, translationPatternPlaces, isFormReady, isDataFecthed]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.abstractProductList));
  };

  const addPlace = (setFieldValue: any, values: FormState, index: number, placeUid: string) => {
    const trPlace = translationPatternPlaces.find((tp) => tp.uid === placeUid);
    if (trPlace) trPlace.used = true;
    setTranslationPatternPlaces(translationPatternPlaces);
    setFieldValue(`patterns.${index}.places`, [...values.patterns[index].places, placeUid]);
  };

  const removePlace = (setFieldValue: any, values: FormState, index: number, placeUid: string) => {
    const trPlace = translationPatternPlaces.find((tp) => tp.uid === placeUid);
    if (trPlace) trPlace.used = false;
    setTranslationPatternPlaces(translationPatternPlaces);
    values.patterns[index].places = values.patterns[index].places.filter((place) => place !== placeUid);
    setFieldValue(`patterns.${index}.places`, values.patterns[index].places.filter((place) => place !== placeUid));
  };

  const removePattern = (values: FormState, index: number) => {
    values.patterns[index].places.forEach((placeUid) => {
      const trPlace = translationPatternPlaces.find((tp) => tp.uid === placeUid);
      if (trPlace) trPlace.used = false;
    });
    setTranslationPatternPlaces(translationPatternPlaces);
  };

  return (
    <PageContent title="Translation patterns" subTitle="" isLoading={isLoading}>
      {isFormReady && (
      <Formik
        initialValues={formData}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await apiRequest(
              ApiEndpoint.abstractProductTranslationPatternCollectionV1Edit,
              {
                abstractProductId: Number(params.abstractProductId),
                patterns: prepareFormDataForSend(values),
              },
              'data',
              'POST',
            );
            setIsLoading(false);
            onBackClick();
          } catch (e) {
            setFormData(values);
            setIsLoading(false);
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit={translationPatternPlaces.filter((place) => !place.used).length === 0} />

            <Card header="Translation patterns" className="mb-3">
              <FieldArray name="patterns">
                {({ remove, push }) => (
                  <>
                    <div className="text-right">
                      <div
                        className="btn btn-success"
                        onClick={
                          () => {
                            push({
                              pattern: '',
                              places: [],
                            });
                          }
                        }
                      >
                        Add pattern
                      </div>
                    </div>
                    <table className="table">
                      <tr>
                        <th className="w-50">
                          <InputLabel text="Translation pattern" />
                        </th>
                        <th>
                          {
                            translationPatternPlaces.filter((place) => !place.used).map((place) => (
                              <span key={`place_header_${place}`}>
                                <span className="badge badge-warning">
                                  {place.name}
                                  {`(${place.uid})`}
                                </span>
                                <span> </span>
                              </span>
                            ))
                          }
                        </th>
                      </tr>
                      {
                        values.patterns.map((pattern, index) => (
                          <tr key={`pattern_${index}`}>
                            <td>
                              <InputText
                                name={`patterns.${index}.translationPattern`}
                                validate={requiredValidator}
                              />
                            </td>
                            <td>
                              {
                                pattern.places.map((placeUid) => (
                                  <span key={`place_used_${placeUid}`}>
                                    <span className="badge badge-primary" onClick={() => removePlace(setFieldValue, values, index, placeUid)}>
                                      {placeUid}
                                    </span>
                                    <span> </span>
                                  </span>
                                ))
                              }
                              {
                                translationPatternPlaces.filter((place) => !place.used).map((place) => (
                                  <span key={`place_not_used_${place.uid}`}>
                                    <span className="badge badge-secondary" onClick={() => addPlace(setFieldValue, values, index, place.uid)}>
                                      {place.name}
                                    </span>
                                    <span> </span>
                                  </span>
                                ))
                              }
                            </td>
                            <td>
                              <div
                                className="btn btn-sm btn-danger"
                                onClick={() => { removePattern(values, index); remove(index); }}
                              >
                                Remove
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </table>
                  </>
                )}
              </FieldArray>

            </Card>
            <FormConfirmButtonsBar onBack={onBackClick} showSubmit={translationPatternPlaces.filter((place) => !place.used).length === 0} />
          </Form>
        )}
      </Formik>
      )}
    </PageContent>
  );
};

export default AbstractProductTranslationPattern;
