/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import CRUDTemplate from 'src/component/CRUDTemplate';
import { getUrl, RouteName } from 'src/routing';
import { bulkEdit, deleteContentfulSync, listContentfulSync } from 'src/service/ContentfulSync';
import { bulkEditForm, tableHeaders } from './config';
import ContentfulSyncItem from './ContentfulSyncItem';

interface ContentfulSyncItemType extends AdminBundle.Entity.API.ContentfulSyncControllerV1.IContentfulSync {
  isOutdatedText: string;
  isRemovedText: string;
}

const ContentfulSyncCrud = () => {
  const [contentfulSyncEntities, setContentfulSyncEntities] = useState([] as ContentfulSyncItemType[]);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await listContentfulSync() as AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncListV1Response;
      setContentfulSyncEntities(prepareData(response.entities));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const prepareData = (array: AdminBundle.Entity.API.ContentfulSyncControllerV1.IContentfulSync[]): ContentfulSyncItemType[] => {
    const preparedArray: ContentfulSyncItemType[] = [];
    array.forEach((item) => {
      const preparedItem: ContentfulSyncItemType = {
        ...item,
        isOutdatedText: item.isOutdated ? 'uptodate' : 'latest',
        isRemovedText: item.isRemoved ? 'to be removed' : 'active',
      };
      preparedArray.push(preparedItem);
    });
    return preparedArray;
  };

  const confirmDeletion = async () => {
    try {
      await deleteContentfulSync(idToDelete);
      await fetchData();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const initiateDelete = (id: number) => {
    setIdToDelete(id);
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure?')) {
      confirmDeletion();
    }
  };

  const onBulkEdit = async (values: any, selectedIds: [number | string]) => {
    const request: AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncBulkEditV1Request = {
      ids: selectedIds as [string],
      partial: {
        contentType: values.contentType ? { string: values.contentType } : undefined,
        lastSyncStatus: values.lastSyncStatus ? { string: values.lastSyncStatus } : undefined,
        isOutdated: (values.isOutdated !== undefined) ? { bool: values.isOutdated } : undefined,
        isRemoved: (values.isRemoved !== undefined) ? { bool: values.isRemoved } : undefined,
      },
    };
    await bulkEdit(request);
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <CRUDTemplate<ContentfulSyncItemType>
        items={contentfulSyncEntities}
        tableHeaders={tableHeaders}
        title="Contentful Sync"
        loading={loading}
        TableRowComponent={ContentfulSyncItem}
        addNewRoute={getUrl(RouteName.contentfulSyncAdd)}
        deleteAction={initiateDelete}
        bulkEditConfig={bulkEditForm}
        bulkEditAction={onBulkEdit}
        allowSelection
        showDeleteButton
        showEditButton
      />
    </div>
  );
};

export default ContentfulSyncCrud;
