import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdminBundle } from 'src/api/optimalprint-sdk.d';
import CRUDTemplateForm from 'src/component/CRUDTemplate/CRUDTemplateForm';
import PageContent from 'src/component/PageContent';
import { getUrl, RouteName } from 'src/routing';
import { addContentfulSync, editContentfulSync, getContentfulSync } from 'src/service/ContentfulSync';
import { addForm, editForm } from './config';

const ContentfulSyncEdit = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('Add new abstract contentful sync entity');
  const emptyItem: AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncAddV1Request = {
    contentType: '',
    id: '',
  };

  const [
    editedItem,
    setEditedItem,
  ] = useState<
    AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncAddV1Request
    | AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncEditV1Request>(emptyItem);

  const params = useParams<{ id: string | undefined }>();
  const { id: contentfulSyncEntityId } = params;

  const fetchData = async (id?: string) => {
    setIsLoading(true);
    if (id) {
      setTitle('Edit new abstract contentful sync entity');
      const response = await getContentfulSync(
        id,
      ) as AdminBundle.Entity.API.ContentfulSyncControllerV1.ContentfulSyncGetV1Response;
      setEditedItem(response.entity);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(contentfulSyncEntityId);
  }, [contentfulSyncEntityId]);

  const onBackClick = () => {
    history.push(getUrl(RouteName.contentfulSync));
  };

  return (
    <PageContent title={title} isLoading={isLoading}>
      <CRUDTemplateForm
        editedItem={editedItem}
        formConfig={contentfulSyncEntityId ? editForm : addForm}
        onSubmit={async (values) => {
          setIsLoading(true);
          try {
            if (contentfulSyncEntityId) {
              await editContentfulSync(values);
            } else {
              await addContentfulSync(values);
            }
            onBackClick();
          } catch (e) {
            setEditedItem(values);
            setIsLoading(false);
          }
        }}
        onBackClick={onBackClick}
      />
    </PageContent>
  );
};

export default ContentfulSyncEdit;
