import { FieldTypes, FormConfig, TableHeader } from 'src/component/CRUDTemplate/types';

export const tableHeaders: TableHeader[] = [
  {
    title: 'ID',
  },
  {
    title: 'Abstract product slug',
  },
  {
    title: 'Theme',
  },
  {
    title: 'Suggested product UID',
    // width: '200px',
  },
  {
    title: 'Include tags',
  },
  {
    title: 'Exclude tags',
    // width: '100px',
  },
  {
    title: 'Enabled',
  },
  {
    title: 'Generation type',
  },
];

export const editForm: FormConfig = {
  fields: [
    {
      name: 'generationType',
      label: 'Generation type',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'theme',
      label: 'Theme',
      type: FieldTypes.InputTextField,
      required: true,
    },
    {
      name: 'enabled',
      label: 'Enabled',
      type: FieldTypes.InputCheckboxField,
      required: true,
    },
  ],
};
