import classNames from 'classnames';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MainContentArea from 'src/component/MainContentArea';
import TopBar from 'src/component/TopBar';
import styles from './App.module.scss';

const App = () => (
  <>
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <BrowserRouter>
      <TopBar />
      <div
        className={classNames({
          'container-fluid': true,
          [styles.containerFluid]: true,
        })}
      >
        <MainContentArea />
      </div>
    </BrowserRouter>
  </>
);

export default App;
